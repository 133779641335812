var $window = $(window),
	$body = $('body');

/*global $:false*/
/*jslint browser: true*/

/* Global Variables */
var servicesSystemMenuPath = "Views/Provider/Partials/Client/services-menu.cshtml", //variables used in the "Find Services" menu
	servicesMenuElement = "ul#ServicesMenu",
	servicesMenuParentElement = ".FindServices h2",
	servicesFacetId = "",
	
	//variables used in the "Find a Doctor" menu
	specialtiesSystemMenuPath = "Views/Provider/Partials/Client/specialty-menu.cshtml",
	specialtiesMenuElement = "ul#FindADoctorMenu",
	specialtiesMenuParentElement = ".FindDoctor h2",
	specialtiesFacetId = "",
	
	//varibale used in both menus
	regionTermId = "",
	preloaderElement = "#loadingmessage";


function getURLLocation(){
    var webInstance = '',
        siteURL = window.location.hostname;
    if(siteURL.indexOf('devs') > 0 || siteURL.indexOf('dev') > 0){
        webInstance = 'dev';
    }
    else if(siteURL.indexOf('stage') > 0){
        webInstance = 'stage';
    }
    else{
        webInstance = 'live';
    }
    return webInstance;
}

function getDirectoryLocation(){
    return window.location.pathname;
}

function doctorServiceLinePages(){
    var webInstance = getURLLocation(),
        currentDirectory = getDirectoryLocation(),
        servicePageId = '';
    if(webInstance === 'dev'){
        servicePageId = '2c25363a-1d21-e611-8d72-2c768a4e1b84';
    }
    else if(webInstance === 'stage'){
        // This is the term id on stage, but it may change to match live when live gets copied down to stage during the next major vitalsite release
		//servicePageId = '9d790ea8-1d21-e611-a2c0-005056870fab';
		servicePageId = '2c25363a-1d21-e611-8d72-2c768a4e1b84';
    }
    else if(webInstance === 'live'){
        servicePageId = '2c25363a-1d21-e611-8d72-2c768a4e1b84';
    }
    else{
        //console.error('Invalid Instance of a webpage.');
    }
	//console.log(currentDirectory);
	if(currentDirectory.indexOf('/uncpn-home/') > -1 || currentDirectory.indexOf('/pn/') > -1){
        $(function ()
        {
            $.getTermById({
                termId: servicePageId
            }, function (data)
            {
                compareServiceLines(data);
            });
        });
    }
}

function compareServiceLines(data){
    var services = data.RelatedTerms,
        servicesPages = [];
    
    for(i=0; i<services.length; i++){
        servicesPages.push([services[i].TermId, services[i].Name]);
    }
    $('#ServicesMenu li a').each(function(i, elm){
        for(i=0; i<servicesPages.length; i++){
			if($(elm).attr('class') === servicesPages[i][0]){
                $(elm).attr("href", "/pn/care-treatment/search-results/?serviceTermId="+servicesPages[i][0]);
            }
        }
    });
}

// Convert numbers to words
// copyright 25th July 2006, by Stephen Chapman http://javascript.about.com
// permission to use this Javascript on your web page is granted
// provided that all of the code (including this copyright notice) is
// used exactly as shown (you can change the numbering system if you wish)

// American Numbering System
var th = ['', 'thousand', 'million', 'billion', 'trillion'];
var dg = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
var tn = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
var tw = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

function toWords(s) {
    s = s.toString();
    s = s.replace(/[\, ]/g, '');
    if (s != parseFloat(s)) return 'not a number';
    var x = s.indexOf('.');
    if (x == -1) x = s.length;
    if (x > 15) return 'too big';
    var n = s.split('');
    var str = '';
    var sk = 0;
    for (var i = 0; i < x; i++) {
        if ((x - i) % 3 == 2) {
            if (n[i] == '1') {
                str += tn[Number(n[i + 1])] + ' ';
                i++;
                sk = 1;
            } else if (n[i] !== 0) {
                str += tw[n[i] - 2] + ' ';
                sk = 1;
            }
        } else if (n[i] !== 0) {
            str += dg[n[i]] + ' ';
            if ((x - i) % 3 === 0) str += 'hundred ';
            sk = 1;
        }
        if ((x - i) % 3 == 1) {
            if (sk) str += th[(x - i - 1) / 3] + ' ';
            sk = 0;
        }
    }
    if (x != s.length) {
        var y = s.length;
        str += 'point ';
        for (var i = x + 1; i < y; i++) str += dg[n[i]] + ' ';
    }
    return str.replace(/\s+/g, ' ');
}

$(document).on('ready', function () {
	"use strict";
	
	// Get taxonomy term and facet id's from page source. 
	// Go into Taxonomy Config and assign these id's via Administration Group to "Pages"
	$(VitalSite.taxonomy).each(function (i, elem) {
		//get TermId for the Region UNC Physicians Network
		if (this.FacetPropertyName === 'Regions') {
			$(this.Terms).each(function (h, elem) {
				switch (this.Name){
					case "UNC Physicians Network":
						regionTermId = this.TermId;
						break;
				}
			});
		}
		//get FacetId for Services		
		if (this.FacetPropertyName === 'ServiceLine') {
			servicesFacetId = this.FacetId;
			//searchTermIds.push(servicesFacetId);
		}
		//get FacetId for Specialties		
		if (this.FacetPropertyName === 'Specialties') {
			specialtiesFacetId = this.FacetId;
			//searchTermIds.push(specialtiesFacetId);
		}
	});



	// initializing clicktocall DA before sliders() is run to fix focusable content being hidden issue
	var clickToCall = ClickToCall.Create();
	clickToCall.Setup();

	function insertLoadingIcon(elem) {
		//if element exists on page, do work
		if ($(elem).length) {
			//if loading image does not exist already within element, implement it
			if (!$(elem + ' #loadingmessage').length) {
				$("<div id='loadingmessage' ><img src ='/imgs/slider_preloader.gif' / ></div>").appendTo(elem);
			}
		}
	}

	function buildMenu(regionTermId, menuFacetId, elements, view, parentElement, menuName) {
		//insert preloader animation while waiting for data
		insertLoadingIcon(parentElement);
		
		//prepend any option
		var anyOption = $('<li><a href="/pn/care-treatment/search-results/?serviceTermId=00000000-0000-0000-0000-000000000000">All ' + menuName + '</a></li>');
		
		//documentation at http://geocentral.geonetric.com/pages/viewpage.action?pageId=38994156
		$.getFacetsAndObjectCountHtml({
			ObjectTypeCode: 17,
			SearchTermIds: regionTermId,
        	OutputFacetIds: menuFacetId,
			//view is the path to the Razor view in the SVN trunk
			RelativeViewPath: view
		}, function (data) {
			//append data to menu
			$(elements).html(data);
			
			//clone "any" element to menu list
			$($(elements + ' > .selected').clone()).prependTo(elements + ' .options');
			
			//remove preloader after data is loaded
			$(parentElement + " " + preloaderElement).remove();
			
			// Build out Find a Service menu
			if ($(servicesMenuElement + " li").length) {
				//$(specialistSearchButton).show();
				//$(elements).show();
				anyOption.prependTo(elements);
			}
			doctorServiceLinePages();
		});
	}

	
	function buildFindADocMenu(searchIds, outputFacetIds, elements, view, parentElement, menuName) {
		//insert preloader animation while waiting for data
		insertLoadingIcon(parentElement);
		
		//prepend any option
		var anyOption = $('<li><a href="/pn/find-a-doctor/doctor-search-results/?termId=">All ' + menuName + '</a></li>');
		//documentation at http://geocentral.geonetric.com/pages/viewpage.action?pageId=38994156
		
		$.getFacetsAndObjectCountHtml({
			ObjectTypeCode: 5,
			//searchIds collected from menu selections in forms
			SearchTermIds: searchIds.toString(), //facetIds collected from taxonomy object embedded in source of page
			OutputFacetIds: outputFacetIds.toString(),
			//view is the path to the Razor view in the SVN trunk
			RelativeViewPath: view
		}, function (data) {
			//append data to menu
			$(elements).html(data);
			
			//clone "any" element to menu list
			$($(elements + ' > .selected').clone()).prependTo(elements + ' .options');
			
			//remove preloader after data is loaded
			$(parentElement + " " + preloaderElement).remove();
			
			//show search button once data is loaded into all menus in Option B
			if ($(specialtiesMenuElement + " li").length) {
				anyOption.prependTo(elements);
			}
		});
	}
	
	//build specialty menu in Option B
	buildMenu(regionTermId, servicesFacetId, servicesMenuElement, servicesSystemMenuPath, servicesMenuParentElement, "Services");
	
	//build Find a Doctor menu
	buildFindADocMenu(regionTermId, specialtiesFacetId, specialtiesMenuElement, specialtiesSystemMenuPath, specialtiesMenuParentElement, "Doctors");
	
	
	// Find a Doctor
	$('#OpenDoctorMenu').on('click', function () {
		$('#FindADoctorMenu').toggle();
		return false;
	});
	
	// Find a Service
	$('#OpenServicesMenu').on('click', function () {
		$('#ServicesMenu').toggle();
		return false;
	});
	
	// Remove message panel if empty
	if($('.Message').html().trim().length <= 0) {
		$('#MessageDisplayWrap').remove();
	}
	
	// Remove alert panel if empty
	if($('#alert-content').html().trim().length <= 0) {
		$('#alert-wrap').remove();
	}
	
	// Add gray background to Back to top container if the last row on the page has a background color
	if ($('.WhiteRow:last #BelowPageContent').html().trim().length <= 0) {
		$('.WhiteRow:last').remove();
		$('#ToTopOuterWrap').addClass('RowBackground');
	}
	
	// AbovePageContent Options
	var AboveNumOfPanels = $('#AbovePageContent .cmspanel').length;
	var AboveNumOfPanelsWord = toWords(AboveNumOfPanels);
	$('#AbovePageContent .cmspanel').each(function (/*i, video, count*/) {
		if ($(this).find('iframe').length) {
			$(this).addClass('Video');
			$('#AbovePageContent').addClass('WithVideo');
		}
		if ($(this).find('.flexslider').length) {
			$(this).addClass('Slider');
			$('#AbovePageContent').addClass('WithSlider');
		} 
	});
	if (AboveNumOfPanels !== undefined) {
		$('#AbovePageContent').addClass(AboveNumOfPanelsWord);
	}
	
	// BelowPageContent Options
	var NumOfPanels = $('#BelowPageContent .cmspanel').length;
	var NumOfPanelsWord = toWords(NumOfPanels);
	$('#BelowPageContent .cmspanel').each(function (/*i, video, count*/) {
		if ($(this).find('iframe').length) {
			$(this).addClass('Video');
			$('#BelowPageContent').addClass('WithVideo');
		}
		if ($(this).find('.flexslider').length) {
			$(this).addClass('Slider');
			$('#BelowPageContent').addClass('WithSlider');
		} 
	});
	if (NumOfPanels !== undefined) {
		$('#BelowPageContent').addClass(NumOfPanelsWord);
	}
	
	// Count Num of boxes in main page 
	// AbovePageContent Options
	var NumOfBoxes = $('#PageContent .Box').length;
	var NumOfBoxesWord = toWords(NumOfBoxes);
	if (NumOfBoxes !== undefined) {
		$('#PageContent .Box').addClass(NumOfBoxesWord);
	}
	
	// Banner Focus Point on Interior Pages
//	$('#Banner .focuspoint').focusPoint({
//		reCalcOnWindowResize: true,
//		throttleDuration: 100,
//	});
	$('.flexslider.Doctors .focuspoint').focusPoint({
		reCalcOnWindowResize: true,
		throttleDuration: 100,
	});
	
});

$window.on('load', function () {
		// Run Equal Heights script inbetween breakpoints
		var WindowWidth = Math.max($(window).width(), window.innerWidth);
		if (WindowWidth >= 1025) {
			if (!$('#BelowPageContent').hasClass('WithSlider')){
				// EqualHeights function lives in client.js which is loaded before this .js file
				equalHeight.equalHeight($("#BelowPageContent .cmspanel"));
			}
			if (!$('#AbovePageContent').hasClass('WithSlider')){
				equalHeight.equalHeight($("#AbovePageContent .cmspanel"));
			}
		}	
});

$(window).on('resize', function() {
		// Run Equal Heights script inbetween breakpoints
	var WindowWidth = Math.max($(window).width(), window.innerWidth);
	if (WindowWidth >= 1025) {
		if (!$('#BelowPageContent').hasClass('WithSlider')){
			// EqualHeights function lives in client.js which is loaded before this .js file
			equalHeight.equalHeight($("#BelowPageContent .cmspanel"));
		}
		if (!$('#AbovePageContent').hasClass('WithSlider')){
			equalHeight.equalHeight($("#AbovePageContent .cmspanel"));
		}
	} else {
		equalHeight.clear($("#BelowPageContent .cmspanel"));
		equalHeight.clear($("#AbovePageContent .cmspanel"));
	}
});